import React from 'react';
import { CardNavigation, CardSize } from '@naf/cards';
import { NavCardLink } from '../styled-link/StyledLink';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { RelatedArticleType } from '../../../../types/articleType';
import { ShortCutCol } from './Styles';

export const SecondaryEntryCard = ({ entry }: { entry: RelatedArticleType }) => {
  const itemUrl = useDocumentUrlWithNode(entry) || '';
  return (
    <ShortCutCol s="12" m="6" l="4" xl="4" key={`${entry.name}${entry.slug}`}>
      <NavCardLink to={itemUrl}>
        <CardNavigation title={entry.name} size={CardSize.Medium} isInternalLink height="100%" />
      </NavCardLink>
    </ShortCutCol>
  );
};
